import React from 'react';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Link from 'next/link';
import CustomImage from '../CustomImage';

export interface MainProps {
  image?: string;
  title: string;
  paths?: Array<{ title: string; url: string }>;
  IconElement?: React.ReactNode | null;
  staticImage?: StaticImageData;
  hideTitle?: boolean;
  emptyImage?: boolean;
  compressedImage?: string;
}
const PageHeader = ({
  image,
  title,
  paths = [],
  IconElement = null,
  staticImage = null,
  hideTitle = false,
  compressedImage = '',
  emptyImage = false,
}: MainProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container className={`page-header ${matches ? 'mobile-page-header' : ''}`} justifyContent="center" alignItems="center" direction="column">
      <Grid container className="page-header-overlay"></Grid>
      {!emptyImage &&
        (image ? (
          <CustomImage
            className="header-img"
            layout="fill"
            objectFit="cover"
            src={image}
            alt={title}
            blurDataURL={compressedImage}
            placeholder={compressedImage ? 'blur' : 'empty'}
            priority
          />
        ) : (
          staticImage && (
            <CustomImage className="header-img" layout="fill" objectFit="cover" src={staticImage} alt={title} placeholder="blur" priority />
          )
        ))}
      <Grid className="title-paths-container" justifyContent="center" alignItems="center" container direction="column">
        <Grid className="title-container">
          <h1 className="title-container">
            {hideTitle ? '' : title}
            {IconElement && <span>{IconElement} </span>}
          </h1>
        </Grid>
        <Grid className="header-paths">
          {paths.map((path, index: number) => (
            <span className="path-item" key={`path-${index}`}>
              <span className="path-title">
                <Link href={path.url} as={path.url}>
                  <a>{path.title}</a>
                </Link>
              </span>
              {index < paths?.length - 1 && <span className="path-title-arrow"> &#8594; </span>}
            </span>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
