import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IconButton } from '@material-ui/core';

/**
 * SlickNextArrow component: a component to display the next arrow of a slider
 *
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SlickNextArrow = (props: any) => {
  const { className, onClick } = props;

  return (
    <IconButton onClick={onClick} className={`slick-next-arrow ${className}`}>
      <NavigateNextIcon />
    </IconButton>
  );
};

export default SlickNextArrow;
