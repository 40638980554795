import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import withSession from '../lib/session';
import { verifyToken } from '../middleware/auth';
import PageHeadTags from '../components/PageHeadTags';
import { languages } from '../utils/languages';
import { getCorporatedata, getSEOData } from '../shared/settingsMethods';
import PageHeader from '../components/PageHeader';
import { useTranslation } from 'next-i18next';
import HomeContent from '../components/HomeContent';
import { CorporateSlideItem, CorporateItem, SeoDataModel } from '../interfaces';
import { getCorporateItem } from '../helpers/helpers';
import { envVariables } from '../config/config';
import { CORPORATE_HOME_TYPE_ID } from '../utils/StaticPagesIds';

export interface PageProps {
  corporateSlider: CorporateSlideItem[];
  corporateItems: CorporateItem[];
  seoData: SeoDataModel;
}

const IndexPage = ({ corporateSlider, corporateItems, seoData }: PageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeadTags title={seoData?.title || ''} description={seoData?.description || ''} image={seoData?.image || ''} />
      <PageHeader title={t('common:homepage')} emptyImage />
      <HomeContent slides={corporateSlider || []} items={corporateItems || []} />
    </>
  );
};

export const getServerSideProps = withSession(async function ({ req, locale }) {
  const token = await verifyToken(req);

  if (!token && envVariables.REQUIRE_LOGIN) {
    return {
      redirect: {
        destination: `/${locale}/login`,
        permanent: false,
      },
    };
  }

  const currentLanguage = languages.find((l) => l.code == locale);

  let corporateItems = [];
  let corporateSlider = [];
  try {
    const result = await getCorporatedata(currentLanguage?.id);
    if (result.data) {
      corporateItems = result.data?.items || [];
      corporateSlider = result.data?.sliders || [];
      if (corporateItems && corporateItems?.length > 0) {
        corporateItems = corporateItems?.map((item) => {
          const defaultItem = getCorporateItem(item.id);

          return {
            ...item,
            description: item?.description || '',
            image: item?.image || '',
            url: defaultItem?.url || '',
          };
        });
      }
    }
  } catch (error: unknown) {
    console.log('error accured while fetching seo data =>', error);
  }

  let seoData = {};
  try {
    const result = await getSEOData(currentLanguage?.id, CORPORATE_HOME_TYPE_ID);
    seoData = result.data || {};
  } catch (error: unknown) {
    console.warn('error accured while fetching seo data =>', error);
  }

  return {
    props: {
      corporateItems,
      corporateSlider,
      seoData,
      ...(await serverSideTranslations(locale, ['common', 'footer', 'menu', 'search', 'error', 'actuality', 'cookies', 'newsletter', 'candidature'])),
    },
  };
});

export default IndexPage;
