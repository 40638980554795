import React from 'react';
import Image from 'next/image';
import ImagePlaceholder from '../../assets/images/defaultImage.png';

export interface MainProps {
  src?: string | StaticImageData;
  alt?: string;
  height?: string | number;
  width?: string | number;
  className?: string;
  unoptimized?: boolean;
  priority?: boolean;
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive';
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  key?: string;
  placeholder?: 'blur' | 'empty';
  blurDataURL?: string;
  onError?: () => void;
  onClick?: () => void;
  onLoad?: () => void;
  objectPosition?: string | number;
}
function CustomImage({
  src = '',
  alt,
  width,
  height,
  className,
  unoptimized = false,
  priority = false,
  layout = 'fill',
  objectFit = 'contain',
  key = '',
  placeholder = 'empty',
  blurDataURL = '',
  onError = () => {},
  onClick = () => {},
  onLoad = () => {},
  objectPosition = '',
}: MainProps) {
  const [imageError, setImageError] = React.useState(false);

  return src && !imageError ? (
    <Image
      loading={'eager'}
      onClick={() => onClick()}
      onLoad={() => onLoad()}
      onError={() => {
        setImageError(true);
        onError();
      }}
      height={height}
      width={width}
      src={src}
      alt={alt}
      className={className}
      key={key}
      unoptimized={unoptimized}
      priority={priority}
      layout={layout}
      objectFit={objectFit}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      objectPosition={objectPosition}
    />
  ) : (
    <Image
      onClick={() => onClick()}
      onLoad={() => onLoad()}
      onError={() => setImageError(true)}
      height={height}
      width={width}
      src={ImagePlaceholder}
      alt={alt}
      className={className}
      key={key}
      unoptimized={unoptimized}
      priority={priority}
      layout={layout}
      objectFit={objectFit}
      placeholder="blur"
      objectPosition={objectPosition}
    />
  );
}

export default CustomImage;
