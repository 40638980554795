import React from 'react';
import { Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { CorporateItem } from '../../interfaces';
import CustomImage from '../CustomImage';
import Link from 'next/link';

export interface MainProps {
  item: CorporateItem;
}

const CorporateItemDetail = ({ item }: MainProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {!matches ? (
        <Grid container className="corporate-bloc-container">
          <Grid item lg={4} md={4} sm={5} xs={6}>
            <Grid container direction="column" className="corporate-bloc-info">
              <h3 className="corporate-bloc-title">{t(item.title)}</h3>
              {item.description && (
                <Grid container className="corporate-bloc-description">
                  <p>{t(item.description)}</p>
                </Grid>
              )}
              <Grid container justifyContent="flex-end" className="corporate-bloc-btn">
                <Link shallow href={item.url} as={item.url}>
                  <Button className="distination-dropdown-more-btn">{t('common:more')}</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={8} sm={7} xs={6} className="corporate-bloc-img">
            <CustomImage
              src={item.image}
              layout="fill"
              objectFit="cover"
              blurDataURL={item.compressed_image}
              placeholder={item.compressed_image ? 'blur' : 'empty'}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container className="corporate-bloc-container-mobile">
          <Grid item lg={8} md={8} sm={10} xs={12} className="corporate-bloc-img">
            <CustomImage
              src={item.image}
              layout="fill"
              objectFit="cover"
              blurDataURL={item.compressed_image}
              placeholder={item.compressed_image ? 'blur' : 'empty'}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={10} xs={12}>
            <Grid container direction="column" className="corporate-bloc-info">
              <h3 className="corporate-bloc-title">{t(item.title)}</h3>
              {item.description && (
                <Grid container className="corporate-bloc-description">
                  <p>{t(item.description)}</p>
                </Grid>
              )}
              <Grid container justifyContent="flex-end" className="corporate-bloc-btn">
                <Link shallow href={item.url} as={item.url}>
                  <Button className="distination-dropdown-more-btn">{t('common:more')}</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CorporateItemDetail;
