import React from 'react';
import { envVariables } from '../../config/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { languages } from '../../utils/languages';
import { GA_TRACKING_ID } from '../../utils/gtag';
import { useTranslation } from 'next-i18next';
import { ENVIRONMENT_MODE } from '../../utils/constants';

interface MainProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
}
const PageHeadTags = ({ title = '', description = '', image = '', type = '' }: MainProps) => {
  const router = useRouter();
  const currentLanguageCode = router.locale;
  const { t } = useTranslation('common');
  const isProdEnv = envVariables.ENV == ENVIRONMENT_MODE.PROD;
  // social media data
  const socialMediaData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: envVariables.APP_URL,
    sameAs: [
      envVariables.APP_URL,
      envVariables.FACEBOOK_URL,
      envVariables.INSTAGRAM_URL,
      envVariables.TWITTER_URL,
      envVariables.YOUTUBE_URL,
      envVariables.LINKEDIN_URL,
    ],
    author: {
      '@type': 'Organization',
      name: 'Nouvelair',
    },
    image: [`${envVariables.APP_URL}images/ogImage.png`],
    name: 'Nouvelair',
    address: '',
    priceRange: '$$$',
    telephone: '',
  };

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link href={`/images/favicon.ico`} rel="icon" type="image/x-icon" />
        <meta name="robots" content={isProdEnv == true ? 'index, follow' : 'noindex, nofollow'} />
        <meta name="googlebot" content={isProdEnv == true ? 'index' : 'noindex'} />
        <meta name="googlebot-news" content={isProdEnv == true ? 'max-snippet: -1' : 'nosnippet'} />
        <link rel="apple-touch-icon" href="/images/favicon.ico" />

        <link rel="canonical" href={`${envVariables.APP_URL}${router.locale}${router.asPath}`} />

        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
									window.dataLayer = window.dataLayer || [];
									function gtag(){dataLayer.push(arguments);}
									gtag('js', new Date());
									gtag('config', '${GA_TRACKING_ID}', {
										page_path: window.location.pathname,
									});
								`,
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(socialMediaData),
          }}
        />
        <title key={`page-title-${currentLanguageCode}`}>{title || t('head_home_title')}</title>
        <meta name="description" content={description || t('head_home_description')} key={`page-description-${currentLanguageCode}`} />

        <meta
          property="og:url"
          content={`${envVariables.APP_URL}${currentLanguageCode}${router.asPath}`}
          id="meta-location"
          key={`page-url-${currentLanguageCode}`}
        />
        <meta property="og:type" content={type || 'page'} key={`page-type-${currentLanguageCode}`} />
        <meta property="og:title" content={title || t('head_home_title')} key={`page-og-title-${currentLanguageCode}`} />
        <meta property="og:description" content={description || t('head_home_description')} key={`page-og-description-${currentLanguageCode}`} />
        <meta property="og:image" content={image || `${envVariables.APP_URL}images/ogImage.png`} key={`page-og-image-${currentLanguageCode}`} />
        <meta property="og:locale" content={currentLanguageCode} key={`page-og-locale-${currentLanguageCode}`} />
        {languages.map(
          ({ code }, index) =>
            code !== router.locale && <meta property="og:locale:alternate" content={code} key={`page-og-locale-${currentLanguageCode}-${index}`} />,
        )}

        {languages.map(({ code }, index) => (
          <link
            rel="alternate"
            href={`${envVariables.APP_URL}${code}${router.asPath}`}
            key={`page-hreflang-${currentLanguageCode}-${index}`}
            hrefLang={code}
          />
        ))}

        <meta name="twitter:card" content="summary_large_image" key={`page-twitter-card-${currentLanguageCode}`} />
        <meta name="twitter:title" content={title || t('head_home_title')} key={`page-twitter-title-${currentLanguageCode}`} />
        <meta
          name="twitter:description"
          content={description || t('head_home_description')}
          key={`page-twitter-description-${currentLanguageCode}`}
        />
        <meta name="twitter:image" content={image || `${envVariables.APP_URL}images/ogImage.png`} key={`page-twitter-image-${currentLanguageCode}`} />
        <meta name="twitter:image:alt" content={'Nouvelair'} key={`page-twitter-image-alt-${currentLanguageCode}`} />
        <meta name="twitter:creator" content={'Nouvelair'} key={`page-twitter-creator-${currentLanguageCode}`} />
        <meta name="twitter:site" key={`page-twitter-site-${currentLanguageCode}`} content={'@nouvelair'} />
        <meta property="og:url" content={`${envVariables.APP_URL}${currentLanguageCode}${router.asPath}`} />
      </Head>
    </>
  );
};

export default PageHeadTags;
