import React from 'react';
import { Grid } from '@material-ui/core';
import { CorporateItem, CorporateSlideItem } from '../../interfaces';
import CorporateItemDetail from '../CorporateItemDetail';
import SliderCorporate from '../SliderCorporate';
import { defaultSlider } from '../../utils/defaultSlider';

export interface MainProps {
  items: CorporateItem[];
  slides: CorporateSlideItem[];
}

const HomeContent = ({ items, slides }: MainProps) => {
  return (
    <Grid container className="corporate-home-container">
      <SliderCorporate slides={slides && slides?.length > 0 ? slides : [...defaultSlider]} />
      {items.map((item, index) => (
        <CorporateItemDetail key={`corporate-item-${index}`} item={item} />
      ))}
    </Grid>
  );
};

export default HomeContent;
