import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateBefore';
import { IconButton } from '@material-ui/core';

/**
 * SlickPrevArrow component: a component to display the prev arrow of a slider
 *
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SlickPrevArrow = (props: any) => {
  const { className, onClick } = props;

  return (
    <IconButton onClick={onClick} className={`slick-prev-arrow ${className}`}>
      <NavigateNextIcon />
    </IconButton>
  );
};

export default SlickPrevArrow;
