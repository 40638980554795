import React from 'react';
import { Grid } from '@material-ui/core';
import { CorporateSlideItem } from '../../interfaces';
import Slider from 'react-slick';
import SlickNextArrow from '../SlickNextArrow';
import SlickPrevArrow from '../SlickPrevArrow';
import CustomImage from '../CustomImage';

export interface MainProps {
  slides: CorporateSlideItem[];
}

const SliderCorporate = ({ slides }: MainProps) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    focusOnSelect: true,
    slidesToScroll: 1,
    dots: true,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    customPaging: () => (
      <div className="slider-page">
        <span className="slider-dot" />
      </div>
    ),
  };
  return (
    <Grid container className="corporate-slider">
      {slides && slides?.length > 0 && (
        <Slider {...settings}>
          {slides.map((item, index: number) => (
            <div key={`corporate-slide-${index}-${item?.id}`} className="gallery-slider-item">
              <CustomImage
                src={item?.image}
                blurDataURL={item.compressed_image}
                layout="fill"
                objectFit="cover"
                placeholder={item.compressed_image ? 'blur' : 'empty'}
              />
            </div>
          ))}
        </Slider>
      )}
    </Grid>
  );
};

export default SliderCorporate;
